import React from 'react';
import { Box, Drawer, ListItem, List, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@colors/index';
import { ReserveLink } from '@links/index';
import RoundedButton from '@components/Buttons/RoundedButton';

interface CustomDrawerProps {
    drawerOpen: boolean;
    handleDrawerToggle: () => void;
}

const useStyles = makeStyles(() => ({
    listItem: {
        display: 'flex',
        justifyContent: 'center !important',
        textDecoration: 'none',
        color: 'black',
        marginBottom: 15,
        borderRadius: 20,
        '&:hover': {
            backgroundColor: COLORS.LightGray,
            color: COLORS.Blue
        },
        '&.active': {
            color: COLORS.Blue,
            fontWeight: 'bold !important'
        }
    },
    lastListItem: {
        display: 'flex',
        justifyContent: 'center !important'
    }
}));

const CustomDrawer: React.FunctionComponent<CustomDrawerProps> = (props) => {
    const { drawerOpen, handleDrawerToggle } = props;
    const classes = useStyles();

    return (
        <Drawer anchor="top" open={drawerOpen} onClose={handleDrawerToggle}>
            <Box m={2}>
                <List>
                    <ListItem
                        className={classes.listItem}
                        component={NavLink}
                        to="/facilities"
                        onClick={handleDrawerToggle}
                    >
                        <Typography>Facilities</Typography>
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component={NavLink}
                        to="/pricing"
                        onClick={handleDrawerToggle}
                    >
                        <Typography>Pricing</Typography>
                    </ListItem>
                    <ListItem
                        className={classes.listItem}
                        component={NavLink}
                        to="/contact"
                        onClick={handleDrawerToggle}
                    >
                        <Typography>Contact</Typography>
                    </ListItem>
                    <ListItem className={classes.lastListItem}>
                        <Link
                            href={ReserveLink}
                            target="_blank"
                            onClick={handleDrawerToggle}
                            style={{ textDecoration: 'none' }}
                        >
                            <RoundedButton>Reserve Stall</RoundedButton>
                        </Link>
                    </ListItem>
                </List>
            </Box>
        </Drawer>
    );
};

export default CustomDrawer;
