import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
	apiKey: "AIzaSyD8FItz853A7r-O12xTCAz9eQhaC69Yvv4",
	authDomain: "river-point-rv-park.firebaseapp.com",
	projectId: "river-point-rv-park",
	storageBucket: "river-point-rv-park.appspot.com",
	messagingSenderId: "833069164933",
	appId: "1:833069164933:web:6ca2d6a4226a2d1de4fcec",
	measurementId: "G-6PFY43FGS4"
};

firebase.initializeApp(firebaseConfig);
export const firestore = firebase.firestore();
