import React from 'react';
import { Box, Container, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { COLORS } from '@colors/index';
import { AddressLink, ParkGuidelinePDF, PhoneLink } from '@links/index';
import { NavLink } from 'react-router-dom';
import RoundedButton from '@components/Buttons/RoundedButton';

const useStyles = makeStyles(() => ({
    footer: {
        backgroundColor: COLORS.LightGray
    },
    button: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: COLORS.Blue
        },
        '&.active': {
            color: COLORS.Blue,
            fontWeight: 'bold !important'
        }
    }
}));

const Footer: React.FunctionComponent = (props) => {
    const classes = useStyles();

    return (
        <Box component="footer" p={4} bgcolor={COLORS.LightGray}>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6} display='flex'>
                        <Box
                            component={NavLink}
                            to="/"
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'center', md: 'flex-start' }
                            }}
                        >
                            <Box component="img" src="/img/logos/logo.png" alt="logo" height={55} />
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} display='flex'>
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            sx={{ justifyContent: { xs: 'center', md: 'flex-end' } }}
                        >
                            <Grid item>
                                <Typography component={NavLink} className={classes.button} to="/facilities">
                                    Facilities
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component={NavLink} className={classes.button} to="/pricing">
                                    Pricing
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography component={NavLink} className={classes.button} to="/contact">
                                    Contact
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Link href={ParkGuidelinePDF} target="_blank">
                                    <RoundedButton>Park Guidelines</RoundedButton>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color={COLORS.LightBlack} align="center">
                            {'Address: '}
                            <Link color={COLORS.Blue} href={AddressLink} target="_blank">
                                225 SE 10th Street Ontario, OR 97914
                            </Link>
                            {' | Phone: '}
                            <Link color={COLORS.Blue} href={PhoneLink}>
                                (541) 449-7269
                            </Link>
                        </Typography>
                        <Typography variant="body1" color={COLORS.LightBlack} align="center">
                            {`Copyright © ${new Date().getFullYear()}. River Point RV Park`}
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;
