import React from 'react';
import { Box, Container, Typography, Grid, Link, CardMedia, Stack } from '@mui/material';
import { COLORS } from '@colors/index';
import { ReserveLink } from '@links/index';
import RoundedButton from '@components/Buttons/RoundedButton';

const Facilities: React.FunctionComponent = () => {
    return (
        <Box>
            <Grid
                container
                alignItems="center"
                sx={{
                    height: '50vh',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        backgroundImage: 'url(/img/backgrounds/facilitiesBackground.jpeg)',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        position: 'absolute',
                        height: '50vh',
                        width: '100vw',
                        filter: 'brightness(30%)',
                        zIndex: -1
                    }}
                />
                <Container>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography
                                fontWeight={800}
                                color={COLORS.White}
                                textAlign="center"
                                sx={{ fontSize: { xs: 50, md: 65 } }}
                            >
                                Facilities
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontWeight={800} color={COLORS.White} textAlign="center" fontSize={40}>
                                Convenient Location. Modern Amenities
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <Link href={ReserveLink} target="_blank" style={{ textDecoration: 'none' }}>
                                <RoundedButton variant="contained">Reserve</RoundedButton>
                            </Link>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid container my={12}>
                <Container>
                    <Grid container alignItems="center" spacing={8}>
                        <Grid item xs={12} md={6}>
                            <CardMedia sx={{ borderRadius: 10 }} component="img" image="/img/facilities/newClubhouse.jpg" alt="map" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack alignItems="center" textAlign="center" spacing={2}>
                                <Typography fontSize={30} fontWeight={800}>
                                    New Clubhouse
                                </Typography>
                                <Typography fontSize={20}>
                                    Our 3,500 square foot clubhouse provides a place to relax next to the built in
                                    fireplace and to work using lightning fast wifi. Whether you want to lounge around
                                    in the common area, watch a movie or use the full kitchen, our clubhouse is the spot
                                    to do it.
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid container my={12}>
                <Container>
                    <Grid container alignItems="center" spacing={8}>
                        <Grid item xs={12} md={6}>
                            <Stack alignItems="center" textAlign="center" spacing={2}>
                                <Typography fontSize={30} fontWeight={800}>
                                    Laundry & Restroom Facilities
                                </Typography>
                                <Typography fontSize={20}>
                                    When you need a bit more space to freshen up, our clean and spacious bathrooms and
                                    showers will be there waiting for you. If you are looking for more space for your
                                    laundry, we’ll always have commercial washers and dryers at your service.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <CardMedia sx={{ borderRadius: 10 }} component="img" image="/img/facilities/laundry.jpg" alt="map" />
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid container my={12}>
                <Container>
                    <Grid container alignItems="center" spacing={8}>
                        <Grid item xs={12} md={6}>
                            <CardMedia sx={{ borderRadius: 10 }} component="img" image="/img/facilities/parkAmenities.jpg" alt="map" />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Stack alignItems="center" textAlign="center" spacing={2}>
                                <Typography fontSize={30} fontWeight={800}>
                                    Park Amenities
                                </Typography>
                                <Typography fontSize={20}>
                                    Never heard of pickle ball? Well we’ll fix that – come borrow some rackets and a
                                    ball to try the latest exercising craze. While you’re playing, your friends can be
                                    barbecuing some burgers under the adjacent shade pergola. We know you’ll feel at
                                    home in the clubhouse. Each stall has full hookups that include water, sewer and
                                    20/30/50 amp power.
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Box>
    );
};
export default Facilities;
