import React from 'react';
import { Snackbar, Slide, AlertColor, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';

const SlideTransition = (props: any) => {
    return <Slide {...props} direction="down" />;
};

const iconStyles = {
    fontSize: { xs: 20, sm: 25, md: 30 },
    marginRight: 1
}

const useStyles = makeStyles({
    alert: {
        borderRadius: 50,
        alignItems: 'center',
        paddingLeft: 30,
        paddingRight: 30
    }
});

export interface AlertType {
    title: string;
    message: string;
    severity: AlertColor;
    open: boolean;
}

interface AlertMessageProps{
    alert: AlertType;
    handleClose: () => void;
}

export const AlertMessage:React.FunctionComponent<AlertMessageProps> = (props) => {
    const { alert, handleClose } = props;
    const classes = useStyles();

    return (
        <Snackbar
            open={alert.open}
            autoHideDuration={5000}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
            }}
        >
            <Alert
                className={classes.alert}
                variant="filled"
                onClose={handleClose}
                severity={'success' as AlertColor}
                iconMapping={{
                    success: <CheckCircleIcon fontSize="inherit" sx={iconStyles} />,
                    info: <InfoIcon fontSize="inherit" sx={iconStyles} />,
                    warning: <WarningIcon fontSize="inherit" sx={iconStyles} />,
                    error: <ReportIcon fontSize="inherit" sx={iconStyles} />
                }}
                action={(
                    <IconButton onClick={handleClose}>
                        <CloseIcon sx={ { fontSize: 18 } }/>
                    </IconButton>
                )}
            >
                <Typography sx={{ fontSize: { xs: 16, sm: 17, md: 18 } }} fontWeight={700}>
                    {alert.title}
                </Typography>
                <Typography sx={{ fontSize: { xs: 15, sm: 16, md: 17 } }} fontWeight={400}>
                    {alert.message}
                </Typography>
            </Alert>
        </Snackbar>
    );
};
