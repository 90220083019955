export const AddressLink = 'https://maps.app.goo.gl/zVNnmEFQD163vpgD6';
export const PhoneLink = 'tel:5414497269';

export const EmailLink = 'mailto:riverpointrvpark@gmail.com';
export const ParkGuidelinePDF = '/pdf/park-guideliness.pdf';
export const ReserveLink = 'https://www.campspot.com/book/riverpointrvpark';
export const WaitListLink =
    'https://docs.google.com/forms/d/1yIyjcvHEgQylPv_C0vPYijnM4E8hdqubiBdwUDgRWlA/viewform?edit_requested=true';
export const JobApplicationLink =
    'https://docs.google.com/forms/d/16GpzNf6Lw8S74iuGBAnC0yBFW4S1lZrJI0qwV2G-yeg/edit';
export const DirectionsLink =
    'https://www.google.com/maps/place/SE+10th+St,+Ontario,+OR+97914/@44.0235125,-116.9526356,17z/data=!3m1!4b1!4m5!3m4!1s0x54af8fb8c4efa57d:0xe44cd22021fa0fd0!8m2!3d44.0235125!4d-116.9504416';
