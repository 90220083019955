import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import LandingPage from '@layouts/LandingPage';
import appRoutes from '@navigation/routes';
import ScrollToTop from '@components/ScrollToTop';

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<LandingPage />}>
                    {appRoutes.map((prop, key) => {
                        return <Route path={prop.path} element={<prop.element />} key={key} />;
                    })}
                </Route>
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;
