import React from 'react';
import { Box, Container, Typography, Grid, Card, CardHeader, CardContent, CardActions, Link } from '@mui/material';
import StarBorder from '@mui/icons-material/StarBorder';
import RoundedButton from '@components/Buttons/RoundedButton';
import { COLORS } from '@colors/index';
import { ReserveLink } from '@links/index';

const Pricing: React.FunctionComponent = () => {
    return (
        <Box>
            <Grid container py={12}>
                <Container maxWidth="sm">
                    <Typography variant="h3" align="center" color="text.primary" gutterBottom>
                        Nightly & Monthly Sites
                    </Typography>
                    <Typography variant="h5" align="center" color="text.secondary" component="p">
                        River Point RV Park provides an unmatched experience for all our tenants. Sites go fast, so
                        hurry up and book yours today!
                    </Typography>
                </Container>
            </Grid>
            <Grid container mb={12}>
                <Container maxWidth="md">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Nightly"
                                    titleTypographyProps={{ align: 'center' }}
                                    sx={{ backgroundColor: COLORS.LightGray }}
                                />
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $59*
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /per day
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle1" align="center">
                                        * Starting rate. Actual rates may vary based on site, date, and time of
                                        reservation.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link
                                        href={ReserveLink}
                                        target="_blank"
                                        style={{ textDecoration: 'none', width: '100%' }}
                                    >
                                        <RoundedButton fullWidth variant="outlined">
                                            Reserve
                                        </RoundedButton>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Card>
                                <CardHeader
                                    title="Monthly"
                                    titleTypographyProps={{ align: 'center' }}
                                    action={<StarBorder />}
                                    subheader="Most popular"
                                    subheaderTypographyProps={{ align: 'center' }}
                                    sx={{ backgroundColor: COLORS.LightGray }}
                                />
                                <CardContent>
                                    <Box display="flex" justifyContent="center" alignItems="center" my={2}>
                                        <Typography component="h2" variant="h3" color="text.primary">
                                            $535*
                                        </Typography>
                                        <Typography variant="h6" color="text.secondary">
                                            /per month + Electricity
                                        </Typography>
                                    </Box>
                                    <Typography variant="subtitle1" align="center">
                                        * Starting rate. Actual rates may vary based on site, date, and time of
                                        reservation. A deposit is required for longer stays.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Link
                                        href={ReserveLink}
                                        target="_blank"
                                        style={{ textDecoration: 'none', width: '100%' }}
                                    >
                                        <RoundedButton fullWidth variant="contained">
                                            Reserve
                                        </RoundedButton>
                                    </Link>
                                </CardActions>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
        </Box>
    );
};
export default Pricing;
