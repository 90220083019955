import React from 'react';
import { AppBar, Box, Container, Toolbar, Link, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import { COLORS } from '@colors/index';
import { ReserveLink } from '@links/index';
import RoundedButton from '@components/Buttons/RoundedButton';

const theme = createTheme();

const useStyles = makeStyles(() => ({
    toolbar: {
        backgroundColor: 'white'
    },
    sectionDesktop: {
        display: 'none !important',
        [theme.breakpoints.up('md')]: {
            display: 'flex !important'
        }
    },
    sectionMobile: {
        display: 'flex !important',
        [theme.breakpoints.up('md')]: {
            display: 'none !important'
        }
    },
    button: {
        textDecoration: 'none',
        color: 'black',
        '&:hover': {
            color: COLORS.Blue
        },
        '&.active': {
            color: COLORS.Blue,
            fontWeight: 'bold !important'
        }
    }
}));

interface NavbarProps {
    handleDrawerToggle: () => void;
    fixContent?: boolean;
}

const Navbar: React.FunctionComponent<NavbarProps> = (props) => {
    const { handleDrawerToggle, fixContent } = props;
    const classes = useStyles();

    return (
        <Box>
            <AppBar position="fixed">
                <Toolbar disableGutters className={classes.toolbar}>
                    <Container>
                        <Grid container alignItems="center" my={2}>
                            <Grid item xs={6}>
                                <Box component={NavLink} to="/">
                                    <Box component="img" src="/img/logos/logo.png" alt="logo" height={55} />
                                </Box>
                            </Grid>
                            <Grid item xs={6} className={classes.sectionDesktop}>
                                <Grid container alignItems="center" justifyContent="flex-end" direction="row" spacing={2}>
                                    <Grid item>
                                        <Typography component={NavLink} className={classes.button} to="/facilities">
                                            Facilities
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component={NavLink} className={classes.button} to="/pricing">
                                            Pricing
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography component={NavLink} className={classes.button} to="/contact">
                                            Contact
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Link href={ReserveLink} target="_blank" style={{ textDecoration: 'none' }}>
                                            <RoundedButton variant="outlined">Reserve Stall</RoundedButton>
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6} className={classes.sectionMobile}>
                                <Grid container alignItems="center" justifyContent="flex-end">
                                    <Grid item>
                                        <IconButton onClick={handleDrawerToggle}>
                                            <MenuIcon />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </Toolbar>
            </AppBar>
            {fixContent && (
                <Toolbar>
                    <Container>
                        <Box my={2} component="img" src="/img/logos/logo.png" alt="logo" height={55} />
                    </Container>
                </Toolbar>
            )}
        </Box>
    );
};

export default Navbar;
