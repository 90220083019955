import React from 'react';
import { Box, CardMedia, Container, Grid, Stack, Typography, Link } from '@mui/material';
import RoundedButton from '@components/Buttons/RoundedButton';
import { COLORS } from '@colors/index';
import { DirectionsLink, JobApplicationLink, ReserveLink, WaitListLink } from '@links/index';
import { NavLink } from 'react-router-dom';

const Home: React.FunctionComponent = () => {
	return (
		<Box>
			<Grid
				container
				alignItems="center"
				sx={ {
					height: '100vh',
					width: '100%'
				} }
			>
				<Box
					sx={ {
						backgroundImage: 'url(/img/backgrounds/homeBackground.jpg)',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						position: 'absolute',
						height: '100vh',
						width: '100vw',
						filter: 'brightness(15%)',
						zIndex: -1
					} }
				/>
				<Container>
					<Grid container spacing={ 4 }>
						<Grid item xs={ 12 } md={ 7 }>
							<Typography
								fontWeight={ 800 }
								color={ COLORS.White }
								sx={ {
									fontSize: { xs: 50, md: 65 },
									textAlign: { xs: 'center', md: 'left' }
								} }
							>
								Ontario's Premier RV Destination
							</Typography>
						</Grid>
						<Grid item xs={ 12 } md={ 7 }>
							<Grid container spacing={ 2 }>
								<Grid
									item
									display="flex"
									justifyContent="center"
									sx={ { width: { xs: '100%', md: 'auto' } } }
								>
									<Link href={ ReserveLink } target="_blank" style={ { textDecoration: 'none' } }>
										<RoundedButton variant="contained">Reserve</RoundedButton>
									</Link>
								</Grid>
								<Grid
									item
									display="flex"
									justifyContent="center"
									sx={ { width: { xs: '100%', md: 'auto' } } }
								>
									<Link href={ WaitListLink } target="_blank" style={ { textDecoration: 'none' } }>
										<RoundedButton variant="contained">Seasonal Inquiry</RoundedButton>
									</Link>
								</Grid>
								<Grid
									item
									display="flex"
									justifyContent="center"
									sx={ { width: { xs: '100%', md: 'auto' } } }
								>
									<Link href={ JobApplicationLink } target="_blank"
									      style={ { textDecoration: 'none' } }>
										<RoundedButton variant="contained">Job Application</RoundedButton>
									</Link>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Grid container bgcolor={ COLORS.Blue } py={ 6 } px={ 2 }>
				<Container maxWidth="sm">
					<Typography fontSize={ 25 } color={ COLORS.White } textAlign="center">
						River Point RV Park is a brand-new RV Park located in Ontario, Oregon. From our spacious
						clubhouse to our recreational facilities, we know you will love residing in any one of our 179
						sites – each with full hook-ups.
					</Typography>
				</Container>
			</Grid>
			<Grid container my={ 12 }>
				<Container>
					<Grid container alignItems="center" spacing={ 8 }>
						<Grid item xs={ 12 } md={ 6 }>
							<CardMedia component="img" image="/img/home/map.png" alt="map"/>
						</Grid>
						<Grid item xs={ 12 } md={ 6 }>
							<Stack alignItems="center" textAlign="center" spacing={ 2 }>
								<Typography fontSize={ 30 } fontWeight={ 800 }>
									Site Map
								</Typography>
								<Typography fontSize={ 20 }>
									Pick from a selection of RV stalls because we have lots to choose from. Sites range
									from 50’ long all the way up to over 75’ long. At Center Point, never worry about
									being awakened by a loud lawnmower because each site is separated by artificial
									turf. Green grass all year round! Wide streets with extra space for turning will
									take the stress away from parking your rig.
								</Typography>
								<NavLink to="/facilities" style={ { textDecoration: 'none' } }>
									<RoundedButton>Facilities</RoundedButton>
								</NavLink>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Grid container my={ 12 }>
				<Container>
					<Grid container alignItems="center" spacing={ 8 }>
						<Grid item xs={ 12 } md={ 6 }>
							<Stack alignItems="center" textAlign="center" spacing={ 2 }>
								<Typography fontSize={ 30 } fontWeight={ 800 }>
									Location
								</Typography>
								<Typography fontSize={ 20 }>
									Conveniently located right off of I-84. Come enjoy Ontario living!
								</Typography>
								<Link href={ DirectionsLink } target="_blank" style={ { textDecoration: 'none' } }>
									<RoundedButton>Get Directions</RoundedButton>
								</Link>
							</Stack>
						</Grid>
						<Grid item xs={ 12 } md={ 6 }>
							<CardMedia sx={ { borderRadius: 10 } } component="img" image="/img/home/location.jpeg"
							           alt="map"/>
						</Grid>
					</Grid>
				</Container>
			</Grid>
			<Grid container my={ 12 }>
				<Container>
					<Grid container alignItems="center" spacing={ 8 }>
						<Grid item xs={ 12 } md={ 6 }>
							<CardMedia sx={ { borderRadius: 10 } } component="img" image="/img/home/tour.jpg"
							           alt="map"/>
						</Grid>
						<Grid item xs={ 12 } md={ 6 }>
							<Stack alignItems="center" textAlign="center" spacing={ 2 }>
								<Typography fontSize={ 30 } fontWeight={ 800 }>
									Tour the Site
								</Typography>
								<Typography fontSize={ 20 }>
									Stretch your legs in our spacious clubhouse or take a walk to our Pickle Ball court.
									Let your dog play in the fenced dog park. Wash your clothes in 1 of 12 washers and
									dryers (but keep your quarters because this state-of-the-art equipment takes
									cards!). Finally, after a long day of playing, or to get ready in the morning, feel
									free to use 1 of the 8 private bathrooms and showers in the clubhouse.
								</Typography>
								<NavLink to="/facilities" style={ { textDecoration: 'none' } }>
									<RoundedButton>Take a tour</RoundedButton>
								</NavLink>
							</Stack>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</Box>
	);
};
export default Home;
