import React, { useMemo, useState } from 'react';
import {
    Box,
    Card,
    CardContent,
    Container,
    Grid, Link,
    Stack,
    TextField,
    Typography
} from '@mui/material';
import { COLORS } from '@colors/index';
import {AddressLink, EmailLink, PhoneLink} from '@links/index';
import EmailService from '@src/shared/api/Emails/EmailCtrl';
import RoundedButton from '@components/Buttons/RoundedButton';
import { AlertMessage, AlertType } from '@components/AlertMessage';
import { contactEmail } from '@src/shared/constants';

const Contact: React.FunctionComponent = () => {
    const emailService = useMemo(() => new EmailService(), []);
    const [name, setName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [message, setMessage] = useState<string>('');
    const [alert, setAlert] = useState<AlertType>({
        title: '',
        message: '',
        severity: 'success',
        open: false
    })

    const sendEmail = async (e: any) => {
        e.preventDefault();
        try{
            await emailService.sendEmail({
                to: contactEmail,
                message: {
                    subject: `River Point RV Park Contact from ${name}`,
                    text: `email: ${email}, message: ${message}`
                }
            });
            setName('');
            setEmail('');
            setMessage('');
            setAlert({
                title: 'Success',
                message: 'Message send successfully',
                severity: 'success',
                open: true
            })
        }catch (error){
            setAlert({
                title: 'Error',
                message: 'Something went wrong',
                severity: 'error',
                open: true
            })
        }
    }

    return (
        <Box py={12}>
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} textAlign='center'>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2868.913180033344!2d-116.95208699999999!3d44.0231886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54af8fb8dfe53b4d%3A0x56bece3290099dc3!2s225%20SE%2010th%20St%2C%20Ontario%2C%20OR%2097914%2C%20EE.%20UU.!5e0!3m2!1sen!2sus!4v1714516826763!5m2!1sen!2sus"
                            width="500" height="500"
                            frameBorder={0}
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            allowFullScreen
                        />
                        <Typography variant="h4" fontWeight={500} gutterBottom>
                            River Point RV Park
                        </Typography>
                        <Typography variant="body1">
                            {'Address: '}
                            <Link color={COLORS.Blue} href={AddressLink} target="_blank">
                                225 SE 10th Street Ontario, OR 97914
                            </Link>
                        </Typography>
                        <Typography variant="body1">
                            {'Phone: '}
                            <Link color={COLORS.Blue} href={PhoneLink}>
                                (541) 449-7269
                            </Link>
                        </Typography>
                        <Typography variant="body1">
                            {'Email: '}
                            <Link color={COLORS.Blue} href={EmailLink}>
                                riverpointrvpark@gmail.com
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
            <AlertMessage alert={alert} handleClose={() => setAlert({...alert, open: false})} />
        </Box>
    );
};
export default Contact;
